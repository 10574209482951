import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:v": "https://vecta.io/nano",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3 17H2v.1A2.9 2.9 0 0 0 4.9 20h14.2a2.9 2.9 0 0 0 2.9-2.9V17h-1H3z"
    }, null, -1),
    _createElementVNode("path", {
      d: "M21 17H3m18 0h1v.1m-1-.1V6M3 17H2v.1m1-.1V6m2-2h14M5 4a2 2 0 0 0-2 2m2-2h0a2 2 0 0 0-2 2h0m16-2a2 2 0 0 1 2 2m-2-2h0a2 2 0 0 1 2 2h0m1 11.1a2.9 2.9 0 0 1-2.9 2.9m2.9-2.9h0a2.9 2.9 0 0 1-2.9 2.9h0m0 0H4.9m0 0A2.9 2.9 0 0 1 2 17.1M4.9 20h0A2.9 2.9 0 0 1 2 17.1h0",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "12",
      cy: "7",
      r: "1",
      fill: "currentColor",
      opacity: "0.6"
    }, null, -1)
  ])))
}
export default { render: render }