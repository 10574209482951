<script lang="ts" setup>
import { mobileMenuStore } from '@/stores'

const props = defineProps<{
  href: string
}>()

const onClick = () => {
  if (props.href.includes(document.location.pathname)) {
    mobileMenuStore.value = false
  }
}
</script>

<template>
  <a
    :href="props.href"
    class="group flex flex-row items-center text-teal-500 sm:text-gray-700 sm:hover:text-teal-600"
    @click="onClick"
  >
    <slot />
  </a>
</template>
