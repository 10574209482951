import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M20 3H5a1 1 0 00-1 1v13h16V3z" }, null, -1),
    _createElementVNode("path", {
      d: "M20 17V3H5m15 14H5m15 0v4H5m-1-4v3a1 1 0 001 1v0m-1-4V4a1 1 0 011-1v0M4 17h1M5 3v14m0 0v4M16 7H9m0 3h6",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }