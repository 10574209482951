<script lang="ts" setup>
import NavbarBrand from './NavbarBrand.vue'
import NavbarDropdowns from './NavbarDropdowns.vue'
import NavbarMobile from './Vue/NavbarMobile.vue'
import NavbarCTA from './Vue/NavbarCTA.vue'

defineProps<{
  currentPath: string
}>()
</script>

<template>
  <nav
    data-cy="main"
    aria-label="main navigation"
    class="mx-auto flex max-w-[1280px] flex-row items-center justify-end gap-[32px] p-[16px] menu:justify-between menu:px-[32px]"
  >
    <NavbarBrand class="menu:hidden" />
    <div
      class="nav-bar absolute left-0 right-0 top-[-110vh] -z-10 h-[calc(100vh-60px)] overflow-hidden bg-white transition-all duration-300 menu:static menu:left-auto menu:right-auto menu:z-10 menu:flex menu:h-auto menu:max-w-[500px] menu:items-center menu:overflow-visible menu:bg-transparent xl:flex-1"
    >
      <NavbarBrand class="hidden menu:inline" />
      <NavbarDropdowns :current-path="currentPath" />
    </div>
    <NavbarCTA />
    <NavbarMobile />
  </nav>
</template>
