<script lang="ts" setup>
import { computed } from 'vue'
import Icon from './submenuIcon'
import Dropdown from './Dropdown.vue'
import HeaderLink from './HeaderLink.vue'
import MenuLink from './MenuLink.vue'

const props = defineProps<{
  name: string
  currentPath: string
  submenus: any
}>()

const headerLinkTarget = computed(() => null)
</script>

<template>
  <Dropdown
    :name="props.name"
    href="/app"
    modal-classes="menu:left-[-12px] lg:left-[-32px] md:w-[640px]"
    :active="['/app'].includes(currentPath)"
    client:idle
  >
    <div v-for="({ key, products, links }, index) in submenus" :key="key" data-cy="product-dropdown-row">
      <hr v-if="index > 0" class="block border-gray-50 sm:mx-[40px]" />
      <div class="py-[32px] sm:px-[32px]" data-cy="submenu">
        <div class="flex flex-col gap-6 sm:flex-row sm:gap-0">
          <HeaderLink
            v-for="({ title, description, href, icon }, pIndex) in products"
            v-bind="{ title, description, href, headerLinkTarget, srcStr: icon.name }"
            :key="pIndex"
            class="sm:w-[50%] sm:grow"
          />
        </div>

        <ul v-if="links" class="mt-6 grid grid-cols-2 gap-y-3">
          <li v-for="link in links" :key="link.title">
            <MenuLink :href="link.href">
              <Icon
                :key="`gray-sub-${link.href}`"
                :name="link.icon"
                class="mx-[12px] shrink-0 max-sm:hidden"
                stroke-color="gray-500"
                fill-color="gray-100"
                hover-stroke-color="teal-500"
                hover-fill-color="jade-200"
                interactive-colors-on-group
              />
              <Icon
                :key="`teal-sub-${link.href}`"
                :name="link.icon"
                class="mx-[12px] shrink-0 sm:hidden"
                stroke-color="teal-500"
                fill-color="jade-200"
              />
              <span class="font-secondary text-[14px] font-normal leading-[20px]">{{ link.title }}</span>
            </MenuLink>
          </li>
        </ul>
      </div>
    </div>
  </Dropdown>
</template>
