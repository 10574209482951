<script lang="ts" setup>
import { watch } from 'vue'
import { IconActionDeleteLarge, IconMenuHamburger } from '@cypress-design/vue-icon'
import { mobileMenuStore } from '@/stores'

const CLASS_OPEN = 'mobile-navbar-open'

watch(mobileMenuStore, (val) => {
  document.body.classList.toggle(CLASS_OPEN, val)
})
</script>

<script lang="ts">
if (!import.meta.env.SSR) {
  window.onresize = () => {
    if (window.innerWidth > 640 && mobileMenuStore.value) {
      mobileMenuStore.value = false
    }
  }
}
</script>

<template>
  <input id="nav-mobile" v-model="mobileMenuStore" type="checkbox" name="nav-mobile" class="hidden" />
  <label
    for="nav-mobile"
    class="relative z-30 flex h-full w-[24px] cursor-pointer items-center justify-end menu:hidden"
    data-cy="mobile-navbar-toggle"
  >
    <template v-if="mobileMenuStore">
      <span class="sr-only">Close navigation</span>
      <IconActionDeleteLarge size="24" />
    </template>
    <template v-else>
      <span class="sr-only">Open navigation</span>
      <IconMenuHamburger size="24" class="scale-x-flip transform" />
    </template>
  </label>
</template>

<style>
body.mobile-navbar-open {
  @apply overflow-hidden sm:overflow-visible;
}

body.mobile-navbar-open main,
body.mobile-navbar-open footer {
  @apply invisible sm:visible;
}
</style>
