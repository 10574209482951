<script lang="ts" setup>
import AnimatedArrow from '@/components/Vue/AnimatedArrow.vue'
import { computed } from 'vue'
const props = defineProps<{
  green?: boolean
  href?: string
}>()
const tag = computed(() => (props.href ? 'a' : 'button'))
</script>

<template>
  <component
    :is="tag"
    class="group flex content-center items-center justify-center whitespace-nowrap rounded-full py-[4px] pl-[16px] pr-[12px] text-[16px] font-medium leading-[24px]"
    :class="green ? 'bg-jade-200 text-teal-600' : 'text-gray-700'"
    :type="tag !== 'a' ? 'button' : null"
    :href="href"
  >
    <slot />
    <AnimatedArrow />
  </component>
</template>
