import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M22.4284 10.8846L8.29417 8.05774C8.15423 8.02975 8.03085 8.15313 8.05883 8.29308L10.8857 22.4273C10.923 22.614 11.1755 22.6479 11.2607 22.4775L13.8759 17.2471C13.937 17.125 14.0997 17.0986 14.1962 17.1951L18.3586 21.3575C18.4367 21.4356 18.5633 21.4356 18.6414 21.3575L21.3586 18.6403C21.4367 18.5622 21.4367 18.4356 21.3586 18.3575L17.1962 14.1951C17.0997 14.0986 17.1261 13.9359 17.2482 13.8748L22.4786 11.2596C22.649 11.1744 22.6151 10.9219 22.4284 10.8846Z",
      stroke: "currentColor",
      "stroke-width": "2"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M11 0.998901C11.5523 0.998901 12 1.44662 12 1.9989V3.4989C12 4.05119 11.5523 4.4989 11 4.4989C10.4477 4.4989 10 4.05119 10 3.4989V1.9989C10 1.44662 10.4477 0.998901 11 0.998901ZM19.2071 4.20601L18.1464 5.26667C17.7559 5.65719 17.1228 5.65719 16.7322 5.26667C16.3417 4.87614 16.3417 4.24298 16.7322 3.85245L17.7929 2.79179C18.1834 2.40127 18.8166 2.40127 19.2071 2.79179C19.5976 3.18232 19.5976 3.81548 19.2071 4.20601ZM5.70711 17.706C6.09763 17.3155 6.09763 16.6823 5.70711 16.2918C5.31658 15.9013 4.68342 15.9013 4.29289 16.2918L3.23223 17.3525C2.84171 17.743 2.84171 18.3761 3.23223 18.7667C3.62276 19.1572 4.25592 19.1572 4.64645 18.7667L5.70711 17.706ZM2.79289 2.79179C3.18342 2.40127 3.81658 2.40127 4.20711 2.79179L5.26777 3.85245C5.65829 4.24298 5.65829 4.87614 5.26777 5.26667C4.87724 5.65719 4.24408 5.65719 3.85355 5.26667L2.79289 4.20601C2.40237 3.81548 2.40237 3.18232 2.79289 2.79179ZM2 9.9989C1.44772 9.9989 1 10.4466 1 10.9989C1 11.5512 1.44772 11.9989 2 11.9989H3.5C4.05228 11.9989 4.5 11.5512 4.5 10.9989C4.5 10.4466 4.05228 9.9989 3.5 9.9989H2Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }