export function debounce<Arg1, Arg2, Arg3, Args extends any[]>(
  func: (arg1?: Arg1, arg2?: Arg2, arg3?: Arg3, ...args: Args) => void,
  wait: number,
) {
  let timeout: ReturnType<typeof setTimeout>
  return function executedFunction(arg1?: Arg1, arg2?: Arg2, arg3?: Arg3, ...args: Args) {
    const later = () => {
      clearTimeout(timeout)
      func(arg1, arg2, arg3, ...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
