<script lang="ts" setup>
import { computed } from 'vue'
import Icon from './submenuIcon'
import Dropdown from './Dropdown.vue'
import HeaderLink from './HeaderLink.vue'
import type { SubmenuProps, SidebarProps } from './types'

const props = defineProps<{
  name: string
  submenu: SubmenuProps
  sidebar: SidebarProps[]
  mobileVerticalSidebar?: boolean
  currentPath: string
}>()

const navColumns = computed(() => props.submenu.navColumnsCollection.items)

const srcStr = computed(() => props.submenu.image.url)

const linkList = computed(() =>
  props.submenu.navColumnsCollection.items
    .map((c) => c.navLinksCollection.items)
    .flat()
    .filter(({ target }) => target !== '_blank')
    .map(({ href: localHref }) => localHref),
)

const sidebarLinks = computed(() =>
  props.sidebar.map(({ href: localHref }) => localHref).filter((localHref) => !localHref.startsWith('http')),
)

/**
 * <windi-keep
 *   class="group-hover:text-[#1da1f2] group-hover:text-[#ff0000] group-hover:text-[#0077b5]"
 *   class="group-hover:text-[#7289da] group-hover:text-[#000000] group-hover:text-[#3b5998]"/>"
 */
</script>

<template>
  <Dropdown
    :name="name"
    :href="submenu.href"
    :target="submenu.target"
    :modal-classes="`menu:left-[-12px] lg:left-[-32px] overflow-hidden ${navColumns.length > 1 ? 'md:w-[720px]' : 'md:w-[640px]'}`"
    :active="linkList.includes(currentPath) || sidebarLinks.includes(currentPath) || currentPath === submenu.href"
  >
    <div class="mt-[32px] rounded sm:mt-0 sm:grid sm:grid-cols-[1fr_240px]" data-cy="submenu">
      <div class="pb-[32px] sm:p-[32px]">
        <HeaderLink
          v-bind="{
            title: submenu.title,
            description: submenu.description,
            href: submenu.href,
            target: submenu.target,
            srcStr,
          }"
        />
        <hr class="my-[24px] w-full border-gray-50" />
        <div :class="navColumns.length > 1 ? 'grid grid-cols-2 gap-x-2' : ''">
          <div v-for="col in navColumns" :key="col.title">
            <span class="mb-2 font-primary text-[14px] font-semibold leading-[20px] text-gray-900">{{
              col.title
            }}</span>
            <ul class="mt-2 flex flex-col gap-2 text-gray-700">
              <li v-for="link in col.navLinksCollection.items" :key="link.title">
                <a
                  class="font-secondary text-[14px] font-normal leading-[20px] text-teal-500 sm:text-gray-700 sm:hover:text-teal-600"
                  :href="link.href"
                  :target="link.target"
                >
                  {{ link.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        :class="`grid border-t border-t-gray-50 py-[32px] ${
          mobileVerticalSidebar ? 'grid-cols-1' : 'grid-cols-2 sm:grid-cols-1'
        } mb-[32px] gap-y-[16px] p-0 sm:mb-0 sm:grid-rows-6 sm:rounded-r-lg sm:border-0 sm:bg-gray-50 sm:p-[32px]`"
      >
        <a
          v-for="bar in sidebar"
          :key="bar.href"
          class="group m-0 flex items-center gap-3 text-teal-500 hover:text-teal-500 sm:text-current sm:text-gray-900"
          :href="bar.href"
          :target="bar.target"
        >
          <Icon
            :key="`black-${bar.icon}`"
            :name="bar.icon"
            :class="`hidden ${
              bar.color ? `group-hover:text-[${bar.color}]` : 'group-hover:text-teal-500'
            } text-gray-500 transition-all sm:block`"
            fill-color="gray-100"
            hover-fill-color="jade-200"
            interactive-colors-on-group
          />
          <Icon
            :key="`teal-${bar.icon}`"
            :name="bar.icon"
            :class="`text-teal-500 ${
              bar.color ? `group-hover:text-[${bar.color}]` : 'group-hover:text-teal-700'
            } transition-all sm:hidden`"
            fill-color="jade-200"
          />
          <span
            class="font-secondary text-[14px] font-normal leading-[20px] text-inherit transition-all sm:font-medium"
          >
            {{ bar.title }}
          </span>
        </a>
      </div>
    </div>
  </Dropdown>
</template>
