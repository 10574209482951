import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11 6.99927C11 7.55155 11.4477 7.99927 12 7.99927C12.5523 7.99927 13 7.55155 13 6.99927C13 6.44698 12.5523 5.99927 12 5.99927C11.4477 5.99927 11 6.44698 11 6.99927Z"
    }, null, -1),
    _createElementVNode("path", {
      d: "M12 10.9993L16 9.99927M12 10.9993L8 9.99927M12 10.9993V13.9993M12 13.9993L9 17.5M12 13.9993L15 17.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.99927C11.4477 7.99927 11 7.55155 11 6.99927C11 6.44698 11.4477 5.99927 12 5.99927C12.5523 5.99927 13 6.44698 13 6.99927C13 7.55155 12.5523 7.99927 12 7.99927Z",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }, null, -1)
  ])))
}
export default { render: render }