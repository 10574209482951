<script lang="ts" setup>
import { nextTick, onMounted } from 'vue'
import { isLoggedIn, hubspotModalDataId, hubspotModalInstanceId, fetchPayingUser } from '@/stores'
import CTARoundButton from './CTARoundButton.vue'
import AnimatedArrow from '@/components/Vue/AnimatedArrow.vue'

const cloudUrl = import.meta.env.PUBLIC_CLOUD_URL

onMounted(async () => {
  await fetchPayingUser()
})
</script>

<template>
  <div
    data-cy="header-cta"
    class="hidden w-[100px] items-center justify-end gap-[16px] sm:flex sm:w-[200px] lg:w-[160px]"
  >
    <button
      class="group flex items-center whitespace-nowrap pl-[12px] font-secondary text-[16px] font-medium leading-[24px] text-gray-700"
      data-cy="header-contact-sales"
      @click="
        () => {
          hubspotModalInstanceId = 'contact_sales'
          nextTick(() => {
            hubspotModalDataId = 'contact-sales'
          })
        }
      "
    >
      Contact sales
      <AnimatedArrow />
    </button>
    <a
      v-if="!isLoggedIn"
      class="group hidden items-center whitespace-nowrap px-[12px] font-secondary text-[16px] font-medium leading-[24px] text-gray-700 sm:flex"
      :href="`${cloudUrl}/login`"
      data-cy="header-login"
    >
      Log in
      <AnimatedArrow />
    </a>
    <CTARoundButton
      v-if="!isLoggedIn"
      data-cy="header-sign-up"
      :green="true"
      :href="`${cloudUrl}/signup`"
      class="hidden lg:flex"
    >
      Sign up
    </CTARoundButton>
    <CTARoundButton
      v-if="isLoggedIn === true"
      id="cloud_link"
      data-cy="header-cloud"
      green
      :href="cloudUrl"
      class="hidden md:flex"
    >
      Cypress Cloud
    </CTARoundButton>
    <CTARoundButton
      v-if="isLoggedIn === true"
      id="cloud_link_light"
      data-cy="header-cloud-light"
      green
      :href="cloudUrl"
      class="md:hidden"
    >
      Cloud
    </CTARoundButton>
  </div>
</template>
