<script lang="ts" setup>
import { type SVGAttributes, type FunctionalComponent, computed } from 'vue'
import AnimatedArrow from '@/components/Vue/AnimatedArrow.vue'

const props = defineProps<{
  title: string
  description: string
  target?: string
  href?: string
  srcStr: string
}>()

const iconsRaw = import.meta.glob('./assets/**/*.svg', { query: 'component', eager: true })
const icons = Object.entries(iconsRaw).reduce(
  (acc, [key, value]) => {
    const val = value as { default: FunctionalComponent<SVGAttributes> }
    acc[key.replace('./assets/', '').replace('.svg', '')] = val.default as FunctionalComponent<SVGAttributes>
    return acc
  },
  {} as Record<string, FunctionalComponent<SVGAttributes>>,
)

const Icon = computed(() => icons[props.srcStr])
</script>

<template>
  <a class="group flex flex-row gap-4" :href="href" :target="target">
    <span
      class="flex h-10 w-10 items-center justify-center rounded bg-jade-50 fill-jade-200 text-teal-500 transition-all group-hocus:bg-indigo-50 group-hocus:fill-indigo-200 group-hocus:text-indigo-500"
    >
      <Icon stroke="indigo-500" class="h-[24px] w-[24px]" />
    </span>
    <span class="flex flex-col gap-y-[2px]">
      <span
        class="flex items-center whitespace-nowrap font-primary text-[16px] font-semibold leading-[20px] text-teal-500 group-hocus:text-indigo-500 sm:text-teal-600 sm:group-hocus:text-indigo-500"
      >
        {{ title }}
        <AnimatedArrow class="text-gray-400 opacity-0 group-hocus:opacity-100" />
      </span>
      <span class="font-secondary text-[14px] font-normal leading-[18px]">{{ description }}</span>
    </span>
  </a>
</template>
