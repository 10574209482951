<script lang="ts" setup>
import { IconChevronRightLarge, IconChevronLeftLarge } from '@cypress-design/vue-icon'
import { nextTick, ref, watch } from 'vue'
import vClickOutside from '@/utilities/v-click-outside'
import { debounce } from '@/utilities/debounce'
import { mobileMenuStore } from '@/stores'
import useMounted from '@/utilities/useMounted'

withDefaults(
  defineProps<{
    name: string
    href: string
    target?: string
    modalClasses?: string
    active?: boolean
  }>(),
  {
    target: '_self',
    modalClasses: '',
  },
)

const open = ref(false)
const $button = ref<HTMLButtonElement>()
const $dropdown = ref<HTMLDivElement>()
const $wrapper = ref<HTMLDivElement>()

// close it whenever the mobile menu is closed
watch(mobileMenuStore, (value) => {
  if (!value) {
    open.value = false
  }
})

watch(open, (value) => {
  if (value) {
    // trigger the click-outside behavior even on hover
    // useful to close a dropdown opened by keyboard
    $wrapper.value?.click()
  }
})

function toggle(e: Event) {
  open.value = !open.value
}

function preventDefaultOnMobileAndToggle(evt: Event) {
  if (window.innerWidth < 900) {
    evt.preventDefault()
    toggle(evt)
  }
}

const toggleWhenBigScreen = debounce(toggleWhenBigScreenFast, 10)

function toggleWhenBigScreenFast(value?: boolean) {
  if (window.innerWidth >= 900) {
    open.value = value || false
  }
}

const safePolygonLeftWidth = ref(1)
const safePolygonRightWidth = ref(1)

watch(open, (open) => {
  nextTick(() => {
    const buttonRect = $button.value?.getBoundingClientRect()
    const dropDownRect = $dropdown.value?.getBoundingClientRect()
    if (open && buttonRect && dropDownRect) {
      safePolygonLeftWidth.value = (buttonRect.left - dropDownRect.left) * 0.6
      safePolygonRightWidth.value = (dropDownRect.right - buttonRect.right) * 0.6
    }
  })
})

function esc() {
  open.value = false
  $button.value?.focus()
}

const mounted = useMounted()
</script>

<template>
  <div
    class="menu:px-[20px] lg:relative"
    :class="{
      group: !mounted,
    }"
  >
    <svg
      v-if="open"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute right-full top-0 z-10 mr-[-16px] h-[50px]"
      preserveAspectRatio="none"
      :style="{ width: safePolygonLeftWidth }"
    >
      <polygon
        fill="transparent"
        stroke="none"
        points="0,10 10,0 10,10"
        @mouseover="toggleWhenBigScreen(true)"
        @mouseout="toggleWhenBigScreen(false)"
      />
    </svg>
    <svg
      v-if="open"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute left-full top-0 z-10 ml-[-16px] h-[50px]"
      preserveAspectRatio="none"
      :style="{ width: safePolygonRightWidth }"
    >
      <polygon
        points="0,0 10,10 0,10"
        fill="transparent"
        stroke="none"
        @mouseover="toggleWhenBigScreen(true)"
        @mouseout="toggleWhenBigScreen(false)"
      />
    </svg>
    <div
      ref="$wrapper"
      v-click-outside="
        () => {
          toggleWhenBigScreen(false)
        }
      "
      :class="open ? 'open' : ''"
      @mouseover="toggleWhenBigScreen(true)"
      @mouseleave="toggleWhenBigScreen(false)"
      @keyup.esc="esc"
    >
      <a
        :id="`dropdown${name}`"
        ref="$button"
        :data-cy="`dropdown-${name.toLowerCase()}`"
        :href="href"
        :target="target"
        class="nav-bar-link menu:nav-bar-link-hover z-10 !flex w-full cursor-pointer items-center justify-between py-[8px] menu:w-auto menu:py-0"
        :class="{
          'active menu:from-gray-300 menu:to-gray-300': active,
          'menu:nav-bar-link-open': open,
        }"
        @click="preventDefaultOnMobileAndToggle"
        @keyup.space="toggle"
      >
        {{ name }}
        <IconChevronRightLarge stroke-color="gray-500" class="menu:hidden" />
      </a>
      <div
        :aria-labelledby="`dropdown${name}`"
        class="z-50 h-[8px] px-[16px] transition-all duration-300 max-menu:absolute max-menu:-right-full max-menu:bottom-0 max-menu:left-full max-menu:top-0 max-menu:transform menu:mx-[-16px] menu:mt-[-8px] menu:box-content lg:absolute lg:h-[16px] lg:max-w-[120px]"
        :class="
          !mounted
            ? 'max-menu:-translate-x-0 menu:hidden menu:group-hover:block'
            : open
              ? 'max-menu:-translate-x-full menu:block'
              : 'max-menu:-translate-x-0 menu:hidden'
        "
      >
        <div
          ref="$dropdown"
          :class="modalClasses"
          class="dropdown-item absolute left-[16px] right-[16px] z-50 list-none rounded-lg border-none bg-white bg-clip-padding pb-[64px] text-left text-[14px] menu:top-[40px] menu:max-h-[calc(100vh-56px)] menu:overflow-y-auto menu:pb-0 menu:shadow-nav-menu lg:relative lg:top-[12px] lg:overflow-x-hidden"
        >
          <button
            class="mt-[16px] flex w-full items-center gap-[16px] py-[16px] font-secondary text-[16px] font-medium leading-[24px] menu:hidden"
            @click="open = false"
          >
            <IconChevronLeftLarge stroke-color="gray-500" />
            {{ name }}
          </button>
          <hr class="border-gray-50 menu:hidden" />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
